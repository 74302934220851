/* eslint-disable @typescript-eslint/naming-convention */
'use client'

import { type Product, type Maybe } from '@/lib/generated/graphql'

import { Icon } from '@/icons/icon'
import { sendGTMEvent } from '@next/third-parties/google'
import { title } from 'process'
import { useCart } from './cart-context'
import { useState } from 'react'
import { getPostcodeClient } from '@/lib/data/postcode-client'
import {
  APPLIANCE_ONLINE_RETAILER_ID,
  stockAvailableInState,
} from '@/utils/functions/appliances-online-state-inventory'
import { toast, Zoom } from 'react-toastify'
import { CircularProgressBarSpin } from '../library/progress-bar-spin'
import {
  sendKlaviyoEvent,
  createAddToCartEventData,
  type KlaviyoAddToCartEvent,
  KlaviyoEventType,
} from '@/utils/klaviyo-events'
import { useLogin } from '../account/context/use-login'
import { isNullOrEmpty } from '@/utils/string-extensions'
import { type AdvertData } from '@/lib/data/products/get-advert-server'
import { getAdvertClient } from '@/lib/data/products/get-advert-client'

interface IAddToCartTile {
  disabled?: boolean
  vertical?: boolean
  product_price?: Maybe<number>
  product_salePrice?: Maybe<number>
  product_id?: Maybe<string>
  product_brand?: Maybe<string>
  product_categoryId?: Maybe<string>
  product_categoryName?: Maybe<string>
  product_advertLookUp?: Maybe<string>
  product_multiVariant?: Maybe<boolean>
  product_retailerId?: Maybe<string>
  product_name?: Maybe<string>
  product_imageUrl?: Maybe<string>
  overrideAddToCartEvent?: string
  advert: AdvertData
  showOutOfStock: boolean
}

export const AddToCartTile: React.FC<IAddToCartTile> = ({
  disabled,
  vertical,
  product_price,
  product_salePrice,
  product_id,
  product_brand,
  product_categoryName,
  product_advertLookUp,
  product_multiVariant,
  product_categoryId,
  product_retailerId,
  product_name,
  product_imageUrl,
  overrideAddToCartEvent,
  advert,
  showOutOfStock,
}) => {
  const { upsertToCart, cartLoading, userCart } = useCart()
  const { user } = useLogin()
  const [isLoading, setIsLoading] = useState(false)

  const isSingleVariant = !product_multiVariant

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleAddToCart = async () => {
    if (isSingleVariant) {
      setIsLoading(true)
      if (!showOutOfStock) {
        const adata = await getAdvertClient(
          product_advertLookUp as string,
          product_retailerId as string,
        )
        if (adata != null) {
          advert = adata
        }
      }

      const postcode = getPostcodeClient()

      const isProductAvailable =
        product_retailerId === APPLIANCE_ONLINE_RETAILER_ID
          ? stockAvailableInState(
              postcode ?? undefined,
              product_retailerId ?? undefined,
              advert?.stockCodes ?? undefined,
            )
          : true

      if (isProductAvailable && advert?.variantAvailable) {
        const product: Product = {
          product_price,
          product_salePrice,
          product_id,
          product_brand,
          product_categoryName,
          product_advertLookUp,
          product_multiVariant,
          product_categoryId,
          product_retailerId,
          product_name,
          product_imageUrl,
        }
        await upsertToCart(
          product,
          advert?.variantId,
          undefined,
          advert?.stockCodes,
        )

        sendGTMEvent({
          event: isNullOrEmpty(overrideAddToCartEvent)
            ? 'add_to_cart'
            : overrideAddToCartEvent,
          ecommerce: {
            items: [
              {
                item_id: product_id,
                item_name: title,
                currency: 'AUD',
                discount:
                  product_salePrice != null && product_price != null
                    ? product_price - (product_salePrice ?? 0)
                    : 0,
                index: 0,
                item_brand: product_brand,
                item_category: product_categoryName,
                item_category5: product_categoryId,
                item_list_id: 'product_list',
                item_list_name: 'Products List',
                item_variant: advert?.variantId,
                location_id: window.location.href,
                price: product_salePrice ?? product_price,
                quantity: 1,
              },
            ],
          },
        })
        sendKlaviyoEvent<KlaviyoAddToCartEvent>(
          createAddToCartEventData({ product, userCart }),
          KlaviyoEventType.AddToCart,
          user,
        )
      } else {
        // do something here...

        toast(
          <div className='text-white'>
            Out of Stock. Item has not been added to cart
          </div>,
          {
            className: 'bg-red-500 text-white',
            position: 'bottom-center',
            transition: Zoom,
          },
        )
      }
      setIsLoading(false)
    }
  }
  return (
    <div
      className={`absolute bottom-2 mr-2 h-auto justify-end ${vertical ? '' : 'right-2'}`}
    >
      {isLoading ? (
        <CircularProgressBarSpin className='!size-6' />
      ) : (
        <button
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          disabled={disabled || cartLoading}
          onClick={(e) => {
            e.preventDefault()
            handleAddToCart()
          }}
          className={`m-0 flex h-[32px] flex-row items-center justify-end px-0 text-xs font-bold `}
        >
          <Icon
            size='xs'
            color='dark'
            name='add-regular'
            variant='dark'
            className='mr-2'
          />
          Add to cart
        </button>
      )}
    </div>
  )
}
